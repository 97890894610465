const hostname = window.location.hostname.split('.').slice(-2).join('.');

const siteUrl = 'https://dev.api.tramo.pro/';

export function UploadFile(url: any, body: any, token: any) {
  // console.log("====token==>")

  var init = {
    method: 'POST',
    headers: {
      token: token ? token : null,
      // 'Content-Type': 'multipart/form-data',
      // 'content-Type': 'multipart/form-data; boundary=--------------------------386650167365198011592528'
    },
    body: body,
  };
  return fetch(siteUrl + url, init)
    .then((res) =>
      res.json().then((data) => {
        var apiData = {
          status: res.status,
          data: data,
        };
        return apiData;
      })
    )
    .catch((err) => {
      return 'error';
    });
}

export function Api(url: any, apiMethod: any, body: any, token: any) {
  var init =
    apiMethod == 'GET'
      ? {
          method: 'GET',
          headers: {
            //  'Authorization': token
            'Content-Type': 'application/json',
            token: token ? token : null,
          },
        }
      : {
          method: apiMethod,
          headers: {
            token: token ? token : null,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        };

  return fetch(siteUrl + url, init)
    .then((res) =>
      res.json().then((data) => {
        var apiData = {
          status: res.status,
          data: data,
        };
        return apiData;
      })
    )
    .catch((err) => {
      return 'error';
    });
}
