import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
// @mui
import {
  Stack,
  Grid,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  styled,
  SwitchProps,
  Switch,
  Box,
  TableRow,
  Typography,
  TextField,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';

import { _allProducts, _ecommerceBestSalesman } from 'src/_mock/arrays';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import IconButton from '@mui/material/IconButton';
import { Api } from 'src/webservices';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// import { Label } from '@mui/icons-material';

// ----------------------------------------------------------------------
type FormValuesProps = {
  MDBankAccount: string;
  DBankAccount: string;
  ABankAccount: string;
};
// admin / adminDetails;

export default function MyWhilelistedIp() {
  const [ip, setIP] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [showPassword1, setShowPassword1] = React.useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    getAdminIp();
  }, []);

  const getAdminIp = () => {
    let token = localStorage.getItem('token');
    Api(`admin/adminDetails`, 'GET', '', token).then((Response: any) => {
      console.log('======productionCredential response====>', Response);
      if (Response.status == 200) {
        if (Response.data.code == 200) {
          setIP(Response.data.data.adminIp);
          console.log('======productionCredential code 200====>', Response.data.data);
        } else {
          console.log('======productionCredential=======>' + Response);
        }
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>View Update Bank Detail | Fintech Suite</title>
      </Helmet>
      <Grid
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(1, 0.5fr)',
        }}
      >
        <Box sx={{ m: 2 }}>
          <Typography variant="h4" ml={1} mb={3}>
            Production IP
          </Typography>
          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <FormControl sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Production IP</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'text'}
                // endAdornment={
                //   <InputAdornment position="end">
                //     <IconButton
                //       aria-label="toggle password visibility"
                //       onClick={handleClickShowPassword}
                //       onMouseDown={handleMouseDownPassword}
                //       edge="end"
                //     >
                //       {showPassword ? <VisibilityOff /> : <Visibility />}
                //     </IconButton>
                //   </InputAdornment>
                // }
                label="Production IP"
                value={ip}
              />
            </FormControl>
            {/* <Stack alignItems={'center'}>
              <Button variant="contained" size="medium" sx={{ m: 1 }}>
                Edit
              </Button>
            </Stack> */}
          </Stack>
          {/* <Typography variant="h4" ml={1} mt={5}>
            UAT IP
          </Typography>
          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <FormControl sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">UAT IP</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword1 ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword1}
                      edge="end"
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="UAT IP"
                value={''}
              />
            </FormControl>
            <Stack alignItems={'center'}>
              <Button variant="contained" size="medium" sx={{ m: 1 }}>
                Edit
              </Button>
            </Stack>
          </Stack> */}
        </Box>
      </Grid>
    </>
  );
}
