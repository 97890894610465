import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'agent',
  initialState: {
    islOading: false,
    data: {},
    isError: false,
  },
  reducers: {
    agentDetailInRedux(state, action) {
      state.data = action.payload;
    },
  },
});

export default slice.reducer;
export const { agentDetailInRedux } = slice.actions;
