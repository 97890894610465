import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';
import tramoLogo from '../../assets/icons/tramoTrmao-Final-Logo.svg';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 150,
          height: 50,
          display: 'inline-flex',
          margin: 'auto',
          ...sx,
        }}
        {...other}
      >
        <img src={tramoLogo} alt="LOGO" width={'100%'} height={'100%'} />
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
          <defs>
            <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
              <stop offset="0%" stopColor={PRIMARY_DARK} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>
          </defs>

          <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              fill="url(#BG1)"
              d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
            />
            <path
              fill="url(#BG2)"
              d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
            />
            <path
              fill="url(#BG3)"
              d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
            />
          </g>
        </svg> */}
        {/* <svg width="183" height="46" viewBox="0 0 183 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.8916 33.711C20.8916 31.451 25.1716 22.501 25.1716 21.771C25.1716 21.711 25.1116 21.571 25.0516 21.571C24.3716 21.571 17.8116 28.141 16.2116 29.571C11.8216 33.811 9.94165 36.401 8.21165 36.401C7.75099 36.3853 7.31512 36.1885 6.9987 35.8534C6.68227 35.5182 6.51085 35.0718 6.52165 34.611C6.52165 33.021 7.77165 29.901 8.29165 28.441H8.17165C7.45982 29.2381 6.67285 29.9648 5.82165 30.611C5.54165 30.611 5.54165 27.961 5.54165 26.831C5.54165 25.441 6.16165 24.781 7.41165 23.651C8.10165 23.061 13.5716 17.421 14.2616 14.171C14.4916 13.241 14.7716 12.841 15.0516 12.841H19.8216C18.6216 15.961 15.9516 22.331 15.9516 22.661C15.9516 22.791 16.0616 22.861 16.2316 22.861C16.8616 22.861 26.8916 12.451 29.8016 12.451C30.4035 12.4299 31.0032 12.5355 31.5616 12.761C29.3737 9.75303 26.2818 7.52289 22.7371 6.39592C19.1924 5.26895 15.3803 5.30412 11.857 6.49629C8.33369 7.68846 5.28349 9.97526 3.15139 13.0231C1.01929 16.0709 -0.0831533 19.7202 0.00489184 23.4387C0.092937 27.1572 1.36686 30.7503 3.64083 33.6938C5.91479 36.6373 9.0698 38.7772 12.6456 39.8013C16.2214 40.8253 20.0308 40.68 23.5182 39.3866C27.0056 38.0931 29.9885 35.7191 32.0316 32.611C29.4716 34.491 26.0316 36.451 24.2016 36.451C22.2016 36.431 20.8916 35.611 20.8916 33.711Z" fill="#00AB55"/>
<path d="M32.8216 14.7109C32.8264 14.7675 32.8264 14.8244 32.8216 14.8809C32.8216 17.1309 27.2816 27.7409 27.2816 29.4009C27.2816 29.7309 27.3416 30.0609 27.5716 30.0609C28.9916 30.0609 33.9516 25.3809 34.7916 24.6609C34.8518 24.0996 34.8818 23.5355 34.8816 22.9709C34.8937 20.089 34.1856 17.2497 32.8216 14.7109Z" fill="#00AB55"/>
<path d="M73.8217 17.6613C73.3417 13.1813 70.6917 10.6113 67.6717 10.6113C61.8917 10.6113 55.6117 15.3213 54.5617 25.1713C54.2917 25.4613 53.9417 25.8713 53.4617 26.3213C52.1517 27.5213 47.0217 31.4313 43.4617 31.4313C42.0317 31.4313 40.5517 30.1713 40.5517 29.1113C40.5517 28.5113 41.0017 28.3813 41.9817 28.3813C43.9817 28.3813 53.9817 24.5313 53.9817 17.9013C53.9817 13.4013 51.8217 12.0713 48.9817 12.0713C43.0517 12.0713 34.9817 17.7713 34.9817 25.3313V28.4413C34.9817 34.2813 38.0617 36.4413 42.3417 36.4413C47.1817 36.4413 51.5717 33.5213 54.4217 30.8013L54.6617 30.5413C55.3817 34.3113 57.8117 36.4413 61.2817 36.4413C65.5017 36.4413 67.9517 34.7813 70.6917 31.1313C73.8217 24.8013 74.2417 21.3813 73.8217 17.6613ZM47.1817 16.8013C48.1817 16.8013 48.6117 17.6013 48.6117 18.7213C48.6117 21.5713 42.2817 24.1013 40.1717 24.2313C41.0317 21.1113 44.2217 16.8013 47.1917 16.8013H47.1817ZM66.4717 28.4013C65.3217 30.5013 64.0717 30.8613 62.9317 30.8613C61.0517 30.8613 59.8517 29.8613 59.8517 26.8613C59.8517 20.8613 63.6717 16.5913 66.1817 16.5913C68.0617 16.5913 68.4117 18.0813 68.6917 19.1713C69.3817 21.7413 67.9117 25.8413 66.4817 28.4013H66.4717Z" fill="#00AB55"/>
<path d="M81.8217 14.771C82.7298 14.1303 83.8208 13.8006 84.9317 13.831C86.1303 13.7763 87.3054 14.1764 88.2217 14.951C89.0491 15.6774 89.7282 16.5568 90.2217 17.541L92.2217 16.251C91.8526 15.4155 91.3455 14.6482 90.7217 13.981C90.0454 13.2712 89.2369 12.7005 88.3417 12.301C87.3244 11.8551 86.2221 11.6367 85.1117 11.661C83.9629 11.6602 82.8261 11.895 81.7717 12.351C80.7309 12.7833 79.816 13.4712 79.1117 14.351C78.3761 15.2794 77.9714 16.4266 77.9617 17.611C77.8531 18.6961 78.0987 19.7871 78.6617 20.721C79.1986 21.5271 79.9181 22.1951 80.7617 22.671C81.6307 23.1813 82.5486 23.6034 83.5017 23.931C84.3181 24.2261 85.1104 24.5838 85.8717 25.001C86.5936 25.3952 87.2188 25.9451 87.7017 26.611C88.173 27.2569 88.3836 28.0567 88.2917 28.851C88.2673 29.4265 88.123 29.9906 87.8682 30.5071C87.6133 31.0237 87.2535 31.4814 86.8117 31.851C85.8424 32.6559 84.6108 33.0759 83.3517 33.031C82.3344 33.064 81.3266 32.8258 80.4317 32.341C79.6406 31.9042 78.9573 31.296 78.4317 30.561C77.9187 29.832 77.5113 29.0341 77.2217 28.191L75.1017 29.331C75.3817 30.3612 75.8495 31.3308 76.4817 32.191C77.1741 33.1444 78.0776 33.9248 79.1217 34.471C80.3604 35.0953 81.7351 35.4012 83.1217 35.361C84.4517 35.3798 85.7662 35.0743 86.9517 34.471C88.0593 33.9018 89.0026 33.0583 89.6917 32.021C90.4019 30.9859 90.7946 29.766 90.8217 28.511C90.9332 27.4047 90.6729 26.2928 90.0817 25.351C89.5098 24.5259 88.7574 23.842 87.8817 23.351C86.9359 22.8069 85.9536 22.3291 84.9417 21.921C84.1317 21.6372 83.3402 21.3032 82.5717 20.921C81.9297 20.601 81.3716 20.1352 80.9417 19.561C80.522 18.9277 80.3445 18.1645 80.4417 17.411C80.4478 16.8934 80.576 16.3846 80.8158 15.9259C81.0556 15.4671 81.4002 15.0715 81.8217 14.771Z" fill="black"/>
<path d="M114.652 13.2412C113.135 12.1159 111.279 11.5409 109.392 11.6112C107.934 11.6248 106.499 11.9666 105.192 12.6112C103.909 13.2744 102.765 14.1773 101.822 15.2712C101.302 15.8602 100.834 16.4925 100.422 17.1612L101.272 12.1112H98.8017L92.9717 45.7012L95.5917 45.6512L98.4017 29.0712C98.6034 29.9207 98.9055 30.7432 99.3017 31.5212C99.8635 32.6389 100.704 33.5929 101.742 34.2912C102.907 35.0033 104.258 35.3514 105.622 35.2912C107.67 35.3074 109.685 34.7683 111.452 33.7312C113.212 32.6817 114.707 31.2412 115.822 29.5212C117.009 27.7117 117.784 25.6634 118.092 23.5212C118.4 21.5582 118.239 19.5501 117.622 17.6612C117.1 15.922 116.065 14.3812 114.652 13.2412ZM115.652 23.4712C115.379 25.1884 114.73 26.824 113.752 28.2612C112.814 29.6304 111.585 30.7745 110.152 31.6112C108.739 32.4447 107.121 32.864 105.482 32.8212C104.424 32.8416 103.382 32.557 102.482 32.0012C101.626 31.4621 100.921 30.7157 100.432 29.8312C99.9094 28.8992 99.5508 27.8845 99.3717 26.8312C99.1712 25.7217 99.151 24.5872 99.3117 23.4712L99.3717 23.1712C99.5546 22.0459 99.9122 20.9561 100.432 19.9412C101.002 18.8441 101.726 17.8339 102.582 16.9412C103.41 16.0586 104.398 15.3395 105.492 14.8212C106.541 14.3188 107.688 14.0558 108.852 14.0512C110.345 13.9914 111.813 14.4547 113.002 15.3612C114.105 16.2518 114.908 17.4595 115.302 18.8212C115.751 20.3281 115.873 21.9132 115.662 23.4712H115.652Z" fill="black"/>
<path d="M133.252 11.6112C131.875 11.5951 130.543 12.0986 129.522 13.0212C128.843 13.626 128.245 14.3149 127.742 15.0712L128.262 12.0712H125.692L121.692 34.7912H124.262L126.822 20.3312C127.018 19.4332 127.299 18.5559 127.662 17.7112C128.079 16.7059 128.698 15.7971 129.482 15.0412C129.869 14.6919 130.323 14.4235 130.816 14.2518C131.309 14.08 131.831 14.0085 132.352 14.0412C132.838 14.032 133.321 14.1346 133.762 14.3412C134.212 14.5626 134.65 14.8096 135.072 15.0812L136.802 13.0812C136.292 12.6808 135.734 12.3449 135.142 12.0812C134.554 11.7863 133.909 11.6257 133.252 11.6112Z" fill="black"/>
<path d="M144.562 0.00102848C144.322 -0.00744144 144.083 0.0364862 143.862 0.129749C143.641 0.223012 143.443 0.363367 143.282 0.541028C142.956 0.891295 142.777 1.35292 142.782 1.83103C142.776 2.30054 142.955 2.75342 143.282 3.09103C143.447 3.26247 143.646 3.39735 143.866 3.48692C144.087 3.5765 144.324 3.61878 144.562 3.61103C144.804 3.61752 145.045 3.57477 145.27 3.48539C145.495 3.39601 145.7 3.26184 145.872 3.09103C146.04 2.92788 146.173 2.73184 146.263 2.51508C146.352 2.29832 146.396 2.06548 146.392 1.83103C146.395 1.59226 146.351 1.35524 146.261 1.13377C146.172 0.912304 146.04 0.71081 145.872 0.541028C145.704 0.363655 145.5 0.223833 145.274 0.130761C145.049 0.0376883 144.806 -0.00652519 144.562 0.00102848Z" fill="black"/>
<path d="M137.752 34.8313H140.262L144.172 12.1113H141.552L137.752 34.8313Z" fill="black"/>
<path d="M165.462 12.7708C164.39 11.9492 163.06 11.5378 161.712 11.6108C160.076 11.5761 158.473 12.0706 157.142 13.0208C155.839 13.9528 154.779 15.184 154.052 16.6108L154.742 12.1208H152.172L148.222 34.8308H150.822L153.342 20.7008C153.526 19.5052 153.992 18.3706 154.702 17.3908C155.405 16.4029 156.312 15.5775 157.362 14.9708C158.436 14.3462 159.659 14.0215 160.902 14.0308C161.899 13.9582 162.886 14.2694 163.662 14.9008C164.286 15.5107 164.693 16.3079 164.822 17.1708C165.001 18.1609 165.028 19.1726 164.902 20.1708L162.432 34.7908H164.902L167.472 19.8708C167.671 18.5151 167.61 17.1337 167.292 15.8008C167.021 14.6214 166.379 13.5597 165.462 12.7708Z" fill="black"/>
<path d="M182.802 12.1112H179.122L180.482 4.20117H177.912L176.572 12.1112H173.072L172.582 14.5312H176.162L172.722 34.8312H175.192L178.702 14.5312H182.312L182.802 12.1112Z" fill="black"/>
</svg> */}
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to="" component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
