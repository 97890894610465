// routes
import { PATH_DASHBOARD, PATH_PLATFORM } from '../../../routes/paths';
import { PATH_AUTH } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const img = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />
);
const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};
const IMAGE = {
  sms: img('ic_sms'),
  sales: img('ic_sales'),
  setting: img('icon'),
  shape: img('shape'),
};
const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      {
        title: 'Fund Management',
        path: PATH_DASHBOARD.fundmanagement.root,
        icon: ICONS.user,
        children: [
          { title: 'View Update Bank Detail', path: PATH_DASHBOARD.fundmanagement.BankDetail },
          // { title: 'Load Your Wallet', path: PATH_DASHBOARD.fundmanagement.LoadYourWallet },
          { title: 'AEPS Settlement', path: PATH_DASHBOARD.fundmanagement.aepssettlement },
          // { title: 'Fund Requests', path: PATH_DASHBOARD.fundmanagement.FundRequest },
          { title: 'My Fund Deposits', path: PATH_DASHBOARD.fundmanagement.MyFundDeposites },
        ],
      },
      // {
      //   title: 'Transactions',
      //   path: PATH_DASHBOARD.MyTransaction,
      //   icon: ICONS.user,
      // },
      {
        title: 'My Transactions',
        path: PATH_DASHBOARD.MyTransaction.root,
        icon: ICONS.user,
        children: [
          { title: 'My Transactions', path: PATH_DASHBOARD.MyTransaction.MyTransaction },
          { title: 'Wallet Ladger', path: PATH_DASHBOARD.MyTransaction.walletLadger },
        ],
      },
      {
        title: 'My Services',
        path: PATH_DASHBOARD.myservices.root,
        icon: ICONS.user,
        children: [
          { title: 'My Active Services', path: PATH_DASHBOARD.myservices.MyActiveServices },
          { title: 'My Scheme', path: PATH_DASHBOARD.myservices.MyScheme },
        ],
      },

      {
        title: 'API Credentials',
        path: PATH_DASHBOARD.Apicredential,
        icon: ICONS.user,
      },
      {
        title: 'support ticket',
        path: PATH_DASHBOARD.supportticket,
        icon: IMAGE.sms,
      },

      {
        title: ' Billing',
        path: PATH_DASHBOARD.partnerbilling,
        icon: IMAGE.sms,
      },
      {
        title: 'Reference API Docs',
        path: PATH_DASHBOARD.Referenceapidocs,
        icon: IMAGE.sms,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //     },
  //     {
  //       title: 'calendar',
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.lock,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level',
  //       path: '#/dashboard/menu_level',
  //       icon: ICONS.menuItem,
  //       children: [
  //         {
  //           title: 'menu_level_2a',
  //           path: '#/dashboard/menu_level/menu_level_2a',
  //         },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'item_disabled',
  //       path: '#disabled',
  //       icon: ICONS.disabled,
  //       disabled: true,
  //     },

  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.label,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     {
  //       title: 'item_external_link',
  //       path: 'https://www.google.com/',
  //       icon: ICONS.external,
  //     },
  //     {
  //       title: 'blank',
  //       path: PATH_DASHBOARD.blank,
  //       icon: ICONS.blank,
  //     },
  //   ],
  // },
];

export default navConfig;
