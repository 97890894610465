import React, { useEffect, useState, useCallback } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
// @mui
import {
  Container,
  Card,
  Stack,
  Grid,
  InputAdornment,
  Box,
  Button,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tabs,
  Modal,
  FormControlLabel,
  styled,
  SwitchProps,
  Checkbox,
  Typography,
  Switch,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import { _allProducts, _ecommerceBestSalesman } from 'src/_mock/arrays';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Api } from 'src/webservices';

// import { Label } from '@mui/icons-material';

// ----------------------------------------------------------------------

export default function CallBackUrl() {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [edit, setEdit] = useState(true);
  const [whiteListedIp, setWhiteListedIp] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [callback, setCallback] = useState('');

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    let token = localStorage.getItem('token');
    Api(`apiBox/userProfileInfo`, 'GET', '', token).then((Response: any) => {
      console.log('======userProfileInfo==response=====>', Response);
      if (Response.status == 200) {
        if (Response.data.code == 200) {
          setWhiteListedIp(Response.data.data.isIPwhiteListed);
          setAuthKey(Response.data.data.authenticationKey);
          setCallback(Response.data.data.payoutCallbackUrl);
          console.log('======userProfileInfo==code 200=====>', Response.data.data);
        } else {
          console.log('======userProfileInfo_error=======>' + Response);
        }
      }
    });
  };

  const updateCallback = () => {
    setEdit(!edit);
    if (!edit) {
      let token = localStorage.getItem('token');
      let body = {
        payoutCallbackUrl: callback,
        authenticationKey: authKey,
        isIPwhiteListed: whiteListedIp,
      };
      Api(`apiBox/update_API_user`, 'POST', body, token).then((Response: any) => {
        console.log('======userProfileInfo==response=====>', Response);
        if (Response.status == 200) {
          if (Response.data.code == 200) {
            console.log('======userProfileInfo==code 200=====>', Response.data.data);
          } else {
            console.log('======userProfileInfo_error=======>' + Response);
          }
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>View Update Bank Detail | Fintech Suite</title>
      </Helmet>
      <Box sx={{ m: 2 }}>
        <Grid
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <Stack>
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              my={2}
            >
              <Typography variant="h3"> CallBack Url</Typography>
              <Button variant="contained" size="medium" onClick={updateCallback}>
                {edit ? 'edit' : 'save'}
              </Button>
            </Stack>
            <FormControl sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Authentication Key</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                // type={showPassword ? 'text' : 'password'}
                // endAdornment={
                // <InputAdornment position="end">
                //   <IconButton
                //     aria-label="toggle password visibility"
                //     onClick={handleClickShowPassword}
                //     onMouseDown={handleMouseDownPassword}
                //     edge="end"
                //   >
                //     {showPassword ? <VisibilityOff /> : <Visibility />}
                //   </IconButton>
                // </InputAdornment>
                // }
                label="Authentication Key"
                value={authKey}
                onChange={(e) => setAuthKey(e.target.value)}
                disabled={edit}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-password">WhiteListed IP</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={whiteListedIp}
                disabled={edit}
                label="WhiteListed IP"
                onChange={(e) => setWhiteListedIp(e.target.value)}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Callbcak url</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                // type={showPassword2 ? 'text' : 'password'}
                // endAdornment={
                //   <InputAdornment position="end">
                //     <IconButton
                //       aria-label="toggle password visibility"
                //       onClick={handleClickShowPassword2}
                //       onMouseDown={handleMouseDownPassword2}
                //       edge="end"
                //     >
                //       {showPassword2 ? <VisibilityOff /> : <Visibility />}
                //     </IconButton>
                //   </InputAdornment>
                // }
                label="CallBack URL"
                value={callback}
                onChange={(e) => setCallback(e.target.value)}
                disabled={edit}
              />
            </FormControl>
          </Stack>
        </Grid>
      </Box>
    </>
  );
}
