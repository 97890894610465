// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PLATFORM = '/platform';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  // nPinPage:path(ROOTS_AUTH,'/npinpage')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  fundmanagement: {
    root: path(ROOTS_DASHBOARD, '/fundmanagement'),
    LoadYourWallet: path(ROOTS_DASHBOARD, '/fundmanagement/LoadYourWallet'),
    aepssettlement: path(ROOTS_DASHBOARD, '/fundmanagement/aepssettlement'),
    BankDetail: path(ROOTS_DASHBOARD, '/fundmanagement/BankDetail'),
    AccountLimit: path(ROOTS_DASHBOARD, '/fundmanagement/AccountLimit'),
    FundRequest: path(ROOTS_DASHBOARD, '/fundmanagement/FundRequest'),
    MyFundDeposites: path(ROOTS_DASHBOARD, '/fundmanagement/MyFundDeposites'),
  },
  myservices: {
    root: path(ROOTS_DASHBOARD, '/myservices'),
    MyActiveServices: path(ROOTS_DASHBOARD, '/myservices/MyActiveServices'),
    MyScheme: path(ROOTS_DASHBOARD, '/myservices/MyScheme'),
  },
  MyTransaction: {
    root: path(ROOTS_DASHBOARD, '/transactions'),
    MyTransaction: path(ROOTS_DASHBOARD, '/transactions/MyTransaction'),
    walletLadger: path(ROOTS_DASHBOARD, '/transactions/walletLadger'),
  },

  Apicredential: path(ROOTS_DASHBOARD, '/Apicredentials'),
  // MyTransaction: path(ROOTS_DASHBOARD, '/MyTransaction'),
  supportticket: path(ROOTS_DASHBOARD, '/supportticket'),
  partnerbilling: path(ROOTS_DASHBOARD, '/partnerbilling'),
  Referenceapidocs: path(ROOTS_DASHBOARD, '/Referenceapidocs'),

  contentmanagement: {
    root: path(ROOTS_DASHBOARD, '/contentmanagement'),
    faqmanagement: path(ROOTS_DASHBOARD, '/contentmanagement/Faqmanagement'),
    Updateimages: path(ROOTS_DASHBOARD, '/contentmanagement/Updateimages'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    createSch: path(ROOTS_DASHBOARD, '/user/createScheme'),
    EditScheme: path(ROOTS_DASHBOARD, '/user/EditScheme'),
    EditSchemeTable: path(ROOTS_DASHBOARD, '/user/EditSchemeTable'),
    SchemeSetting: path(ROOTS_DASHBOARD, '/user/SchemeSetting'),
    MapSchemeSetting: path(ROOTS_DASHBOARD, '/user/mapSchemeSetting'),
    // SchemeMap: path(ROOTS_DASHBOARD, '/user/schemeMap'),
  },
  advance: {
    root: path(ROOTS_DASHBOARD, '/advance'),
  },
  payments: {
    root: path(ROOTS_DASHBOARD, '/payments'),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    setting: path(ROOTS_DASHBOARD, '/product/setting'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_PLATFORM = {
  tools: {
    root: path(ROOTS_PLATFORM, '/tools'),
    smsmanagement: path(ROOTS_PLATFORM, '/tools/smsmanagement'),
    newsnotifications: path(ROOTS_PLATFORM, '/tools/newsnotifications'),
    vendorswitch: path(ROOTS_PLATFORM, '/tools/vendorswitch'),
    accountrecovery: path(ROOTS_PLATFORM, '/tools/accountrecovery'),
    bankmaster: path(ROOTS_PLATFORM, '/tools/bankmaster'),
    uploadexternaldata: path(ROOTS_PLATFORM, '/tools/uploadexternaldata'),
  },
  reports: {
    root: path(ROOTS_PLATFORM, '/reports'),
    alltransactionrecord: path(ROOTS_PLATFORM, '/reports/alltransactionrecord'),
    userwisetransactionrecord: path(ROOTS_PLATFORM, '/reports/userwisetransactionrecord'),
  },
  refunds: {
    root: path(ROOTS_PLATFORM, '/refunds'),
    walletandfund: path(ROOTS_PLATFORM, '/refunds/walletandfund'),
    aepsrefunds: path(ROOTS_PLATFORM, '/refunds/aepsrefunds'),
    productwiserefund: path(ROOTS_PLATFORM, '/refunds/productwiserefund'),
  },
  legalcompliances: path(ROOTS_PLATFORM, '/legalcompliances'),
  finance: {
    root: path(ROOTS_PLATFORM, '/finance'),
    productwisetransaction: path(ROOTS_PLATFORM, '/finance/productwisetransaction'),
    openingclosingbalance: path(ROOTS_PLATFORM, '/finance/openingclosingbalance'),
    profitability: path(ROOTS_PLATFORM, '/finance/profitability'),
    reconcile: path(ROOTS_PLATFORM, '/finance/reconcile'),
    tdsreport: path(ROOTS_PLATFORM, '/finance/tdsreport'),
    gstreport: path(ROOTS_PLATFORM, '/finance/gstreport'),
  },

  vendormanagement: {
    root: path(ROOTS_PLATFORM, '/vendormanagement'),
    apibalancesheet: path(ROOTS_PLATFORM, '/vendormanagement/apibalancesheet'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/OBEorYicjdbIT6P1YQTTK7/%5BPreview%5D-Minimal-Web.15.10.22?node-id=0%3A1';
